import logo from './img/logo.png';
import Instagram from './img/instagram.svg';
import './css/App.css';

function App() {
  return (
    <div className="App">
      <img className='logo' src={logo} alt="Logo" />
      <ul className='info'>
        <li>🌐 Интернет магазин</li>
        <li>👗Товары на заказ</li>
        <li>🚚 Срок доставки 20 дней</li>
        <li>💯 Люкс копия</li>
        <li>🚀Качество топ</li>
      </ul>
      <p>Наши ссылки</p>
        <div className="buttons">
          <a href="https://www.instagram.com/bigbaraka" rel='noreferrer' target='_blank'><img src={Instagram} alt="instagram" /><span>Instagram</span></a>
        </div>
    </div>
  );
}

export default App;
